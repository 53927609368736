import { Link } from 'gatsby';
import React from 'react';

export default function Index() {
    return (
        <div className='d-flex justify-content-center flex-column vh-100 my-auto bg-gradiant'>
            <div className='container '>
                <div className='row justify-content-md-center mb-5'>
                    <img
                        src={'../../Logo_mit_text.svg'}
                        alt=''
                        className='mx-auto'
                        height='100'
                        width='314'
                    />
                </div>

                <div className='row justify-content-md-center'>
                    <div className='col-md-6'>
                        <div class='list-group'>
                            <Link
                                to='/en'
                                className='list-group-item list-group-item-action fs-3'
                            >
                                🇬🇧 English
                            </Link>
                            <Link
                                to='/de'
                                className='list-group-item list-group-item-action fs-3'
                            >
                                🇩🇪 Deutsch (under construction)
                            </Link>
                            <Link
                                to='/es'
                                className='list-group-item list-group-item-action fs-3'
                            >
                                🇪🇸 Espanol (under construction)
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
